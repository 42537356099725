<template>
  <b-card-actions
    ref="opportunities"
    no-actions
    no-body
  >
    <!-- !! Table Header -->
    <div class="mx-1">
      <b-row>
        <b-col class="mt-1" order="3" cols="12" order-sm="1" sm="6" md="4" lg="4">
          <HeroInputText
            id="filter"
            v-model="tableConfig.filter"
            placeholder="Search"
            @input="doTableFilter(500)"
          />
        </b-col>
        <b-col class="mt-1" order="1" cols="6" sm="4" md="3" lg="2">
          <HeroTablePerPage
            v-model="tableConfig.perPage"
            :options="tableConfig.perPageOptions"
            @input="doTableFilter(100)"
          />
        </b-col>
        <b-col class="mt-1" order="2" cols="6" sm="2" offset-md="2" md="3" offset-lg="4" lg="2" />
      </b-row>
    </div>

    <!-- !! Table -->
    <b-row class="my-1">
      <b-col cols="12">
        <b-table
          show-empty
          striped
          bordered
          responsive
          :sticky-header="true"
          :no-border-collapse="false"
          :items="items"
          :fields="tableConfig.fields"
          :per-page="0"
          :sort-by="tableConfig.sortBy"
          :sort-direction="tableConfig.sortDirection"
          :no-sort-reset="true"
          :no-local-sorting="true"
          style="font-weight: normal; font-size: 12px;"
          @sort-changed="doTableSort"
        >
          <template #head(account_management_status)="data">
            <div class="span span:hover">
              {{ data.label }}
            </div>
          </template>
          <template #head(close_date)="data">
            <div class="span span:hover">
              {{ data.label }}
            </div>
          </template>
          <template #head(account_manager)="data">
            <div class="span span:hover">
              {{ data.label }}
            </div>
          </template>
          <template #cell(name)="data">
            <router-link :to="`/opportunities/${data.item.id}`">
              {{ data.item.name }}
            </router-link>
          </template>
          <template #head(legal_entity)="data">
            <div class="span span:hover">
              {{ data.label }}
            </div>
          </template>

          <template #cell(account_name)="data">
            <router-link :to="`/accounts/f7ae2ba8-4f28-4d65-b529-843d3070cea7`">
              {{ data.item.account_name }}
            </router-link>
          </template>

          <template #cell(client_requirements)="data">
            <div v-html="formatClientRequirements(data.item.client_requirements)" />
          </template>

          <template #cell(management_fee)="data">
            {{ formatPercentage(data.item.management_fee) }}
          </template>

          <template #cell(amount)="data">
            <div class="text-right">
              {{ formatMoney(data.item.amount) }}
            </div>
          </template>

          <template #cell(amount_inclusive_of_tax)="data">
            <div class="text-right">
              {{ formatMoney(data.item.amount_inclusive_of_tax) }}
            </div>
          </template>

          <template #cell(one_time_setup_fee)="data">
            {{ formatUsaDate(data.item.one_time_setup_fee) }}
          </template>

          <template #cell(sf_created_date)="data">
            {{ formatUsaDate(data.item.sf_created_date) }}
          </template>

          <template #cell(sf_last_modified_date)="data">
            {{ formatUsaDate(data.item.sf_last_modified_date) }}
          </template>

          <template #cell(salesforce_link_url)="data">
            <a :href="data.item.salesforce_link_url" target="_blank">
              <feather-icon icon="GlobeIcon" class="mr-50" />
            </a>
          </template>

          <template #cell(close_date)="data">
            {{ formatUsaDate(data.item.close_date) }}
          </template>
        </b-table>
      </b-col>
    </b-row>

    <!-- !! Table Footer -->
    <div class="mx-1 mb-1">
      <b-row>
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
          <HeroTablePagination
            v-model="tableConfig.currentPage"
            :per-page="tableConfig.perPage"
            :total-rows="tableConfig.totalRows"
            @input="doTableFilter(100)"
          />
        </b-col>
        <b-col cols="12" sm="6" class="d-flex align-items-top justify-content-center justify-content-sm-end">
          <HeroTableStatus :per-page="tableConfig.perPage" :total-rows="tableConfig.totalRows" />
        </b-col>
      </b-row>
    </div>
  </b-card-actions>
</template>

<script>
import moment from 'moment'
import ErrorService from '@/services/ErrorService'
import DataFormatService from '@/services/DataFormatService'
import {
  BRow,
  BCol,
  BTable,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import HeroTablePerPage from '@/views/components/table/HeroTablePerPage.vue'
import HeroTablePagination from '@/views/components/table/HeroTablePagination.vue'
import HeroTableStatus from '@/views/components/table/HeroTableStatus.vue'
import SweetAlert from '@/services/SweetAlert'
import OpportunityFactory from '@/factories/AccountDetails/Opportunity'
import axiosInstance from '@/libs/axiosInstance'

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BCardActions,
    HeroInputText,
    HeroTablePerPage,
    HeroTablePagination,
    HeroTableStatus,
  },
  data() {
    return {
      items: [],
      tableConfig: {
        filter: '',
        currentPage: 1,
        perPage: 25,
        perPageOptions: [5, 10, 25, 50, 75, 100],
        totalRows: 0,
        sortBy: 'name',
        sortDirection: 'asc',
        timeInterval: moment(),
        fields: [
          {
            label: 'name',
            key: 'name',
            sortable: true,
            stickyColumn: true,
          },
          {
            label: 'stage',
            key: 'stage',
            sortable: false,
          },
          {
            label: 'account management status',
            key: 'account_management_status',
            sortable: true,
          },
          {
            label: 'amount',
            key: 'amount',
            sortable: false,
          },
          {
            label: 'duration',
            key: 'duration',
            sortable: false,
          },
          {
            label: 'close date',
            key: 'close_date',
            sortable: false,
          },
          {
            label: 'account manager',
            key: 'account_manager',
            sortable: false,
          },
          {
            label: 'legal entity',
            key: 'legal_entity',
            sortable: false,
          },
        ],
      },
    }
  },
  created() {
    this.formatClientRequirements = DataFormatService.formatClientRequirements
    this.formatDefaultText = DataFormatService.formatDefaultText
    this.formatFullName = DataFormatService.formatFullName
    this.formatMoney = DataFormatService.formatMoney
    this.formatPercentage = DataFormatService.formatPercentage
    this.formatUsaDate = DataFormatService.formatUsaDate
  },
  async mounted() {
    this.$refs.opportunities.showLoading = false
    await this.doLoadData()
  },
  methods: {
    doTableFilter(timeout) {
      setTimeout(() => {
        if (moment().diff(this.tableConfig.timeInterval, 'milliseconds') > timeout) {
          this.tableConfig.timeInterval = moment()
          this.doLoadData()
        }
      }, timeout)
    },

    doTableSort(event) {
      this.tableConfig.sortBy = event.sortBy
      this.tableConfig.sortDirection = event.sortDesc ? 'desc' : 'asc'
      this.doLoadData()
    },

    async doLoadData() {
      try {
        this.$refs.opportunities.showLoading = true

        const {
          perPage, currentPage, filter, sortBy, sortDirection,
        } = this.tableConfig

        const url = `/salesforce/opportunity?perPage=${perPage}&page=${currentPage}&q=${filter}&orderByColumn=${sortBy}&orderByDirection=${sortDirection}`
        const response = await axiosInstance.get(url)
        if (response.data.status) {
          this.tableConfig.currentPage = Number(response.data.data.current_page)
          this.tableConfig.perPage = Number(response.data.data.per_page)
          this.tableConfig.totalRows = Number(response.data.data.total)
          this.items = OpportunityFactory.createFromJsonArray(response.data.data.data)
        } else {
          this.tableConfig.currentPage = 1
          this.tableConfig.totalRows = 0
          this.items = []
        }
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })

        this.items = []
        this.tableConfig.totalRows = 0
      } finally {
        if (this.$refs.opportunities) {
          this.$refs.opportunities.showLoading = false
        }
      }
    },
  },
}
</script>

<style>
.span {
  width: 100px; /* can be 100% ellipsis will happen when contents exceed it */
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.span:hover {
  white-space: normal;
  /* or:
  width: auto
  */
}
.b-table-sticky-header {
  max-height: calc(100vh - 310px);
}
</style>
